import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import Masonry from 'react-masonry-component';
import PrimaryLink from '../components/PrimaryLink';
import SEO from '../components/SEO';

const GalleryButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 4rem;
`;

const H2Styles = styled.h2`
  height: 5rem;
  text-align: center;
`;

function SingleGalleryImage({ image }) {
  return (
    <Link to={image.slug.current}>
      <GatsbyImage
        image={image.image.asset.gatsbyImageData}
        alt={image.altText}
        style={{
          filter: 'drop-shadow(0px 5px 10px var(--dark-grey))',
          marginBottom: '4rem',
        }}
        objectFit="contain"
      />
    </Link>
  );
}

function GalleryImageList({ images, count }) {
  if (count) {
    images = images.slice(0, count);
  }

  return (
    <>
      {images.length > 0 ? (
        images.map((image) => (
          <SingleGalleryImage key={image.id} image={image} />
        ))
      ) : (
        <H2Styles>Sorry, there are no images in this gallery yet</H2Styles>
      )}
    </>
  );
}

class GalleryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imagesToShow: 6,
    };

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    this.setState((state) => ({
      imagesToShow: state.imagesToShow + 6,
    }));
  }

  render() {
    const { data, pageContext } = this.props;
    const { gallery } = data;

    const masonryOptions = {
      gutter: 50,
      // columnWidth: 450,
      fitWidth: true,
    };

    return (
      <>
        <SEO title={`${pageContext.galleryName} Gallery`} />
        <Masonry options={masonryOptions} className="gallery">
          <GalleryImageList
            images={gallery.images}
            count={this.state.imagesToShow}
          />
        </Masonry>
        {this.state.imagesToShow < gallery.images.length && (
          <GalleryButtonStyles>
            <a onClick={this.handleClick} />
            <PrimaryLink
              linkText="See more images"
              onClick={this.handleClick}
            />
          </GalleryButtonStyles>
        )}
      </>
    );
  }
}

export const query = graphql`
  query ($galleryId: String!) {
    gallery: sanityGallery(id: { eq: $galleryId }) {
      images {
        id
        altText
        slug {
          current
        }
        image {
          asset {
            gatsbyImageData(
              width: 450
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;

export default GalleryPage;
